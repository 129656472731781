import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext, useEffect, useState } from 'react';

import { Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import Body from '../components/common/body/Body';
import CustomGridCarousel from '../components/common/carousel/CustomGridCarousel';
import CustomFanGoCard from '../components/common/fanGoCard/CustomFanGoCard';
import { FanGoTextProcessor } from '../components/common/portableText';
import StandardSEO from '../components/common/seo/StandardSEO';
import styles from '../components/common/styling/styles';
import { TrustedBrandIcons } from '../components/PageComps/CommonComps';
import BlogsPreview from '../components/PageComps/CommonComps/blogsPreview/BlogsPreview';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import { DataContext } from '../providers/DataProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      color: theme.palette.primary.main,
      // paddingBottom: theme.spacing(2),
    },
    gridItemCenterContent: {
      margin: 'auto!important',
    },
    containerMarginBottom: {
      marginBottom: theme.spacing(5),
    },
  })
);

const responsiveSizes = [
  {
    breakpoint: 1200,
    cols: 3,
    rows: 3,
  },
  {
    breakpoint: 975,
    cols: 2,
    rows: 2,
  },
  {
    breakpoint: 650,
    cols: 2,
    rows: 1,
  },
  {
    breakpoint: 500,
    cols: 1,
    rows: 1,
  },
];

const renderTitleAndTextBlock = ({
  title,
  text,
  classes,
  titleTextAlign = 'center',
  textBodyAlign = 'center',
  titleVariant = 'h2',
}) => {
  return (
    <>
      <Typography
        variant={titleVariant}
        className={classes.sectionTitle}
        sx={{ textAlign: titleTextAlign }}
      >
        {title}
      </Typography>
      <FanGoTextProcessor text={text} textAlign={textBodyAlign} />
    </>
  );
};

const TicketingPage = ({ data }) => {
  const { heroSectionBackgroundImage: imageData } = useContext(DataContext);
  const classes = useStyles();
  const { sanityTicketingPage } = data;
  const { body1, pageHead, seo, softwareFeature, paragraphs } =
    sanityTicketingPage;
  const { title, _rawBody: pageSubTitleText } = pageHead;
  const [screenWidth, setScreenWidth] = useState(0);

  console.log(sanityTicketingPage);

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      const calcWindowSize = () => {
        const screenWidth = window.innerWidth;
        setScreenWidth(screenWidth);
      };
      calcWindowSize();
      window.addEventListener('resize', calcWindowSize);
    }
  }, []);
  // Use this to set the right alignment to center on small screen

  const {
    title: cardsSectionTitle,
    productFeatures: cards,
    _rawBody: cardsSectionSubTitle,
    image: cardsSectionImage,
  } = softwareFeature;
  const { asset: cardsSectionImageAsset, altText: cardsSectionImageAltText } =
    cardsSectionImage;

  const cardsArr = [];
  cards.forEach((item, index) => {
    const { cardImage, _key, feature, description } = item;
    const { asset, altText } = cardImage;
    const image = asset?.gatsbyImageData;
    const card = (
      <CustomFanGoCard
        minHeight="628px"
        cardMaxWidth="400px"
        key={_key}
        title={feature}
        image={image}
        altText={altText}
        text={description}
      />
    );
    cardsArr.push(card);
  });

  let alignment = 'right';
  paragraphs.forEach((paragraph) => {
    const { image } = paragraph;
    if (image) {
      paragraph.alignment = alignment;
      if (alignment === 'right') {
        alignment = 'left';
      } else {
        alignment = 'right';
      }
    }
  });

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="20% 0%"
        imageData={imageData}
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={pageSubTitleText}
            titlePaddingBottom={30}
            titleRowSpacing={0}
            largeSubTitleSize
          />
        }
      />
      <Body paddingY={styles.allFive}>
        <Grid container className={classes.containerMarginBottom}>
          <Grid item xs={12} md={4} className={classes.gridItemCenterContent}>
            <Typography
              variant="h2"
              className={classes.sectionTitle}
              sx={{ textAlign: 'left' }}
            >
              {cardsSectionTitle}
            </Typography>
            <FanGoTextProcessor text={cardsSectionSubTitle} textAlign="left" />
          </Grid>
          <Grid item xs={12} md={8}>
            <GatsbyImage
              image={cardsSectionImageAsset.gatsbyImageData}
              alt={cardsSectionImageAltText || ''}
              objectFit="cover"
              style={{
                borderRadius: '8px 8px 0 0',
              }}
            />
          </Grid>
        </Grid>
        <CustomGridCarousel
          itemsArray={cardsArr}
          responsiveSizes={responsiveSizes}
          defaultCols={3}
          defaultRows={3}
          paddingBottom={2}
          speed={500}
        />
        {paragraphs.map((paragraph, index) => {
          const { title, _rawBody: text, image, alignment } = paragraph;
          let imgData = null;
          const textAlignment = alignment === 'left' ? 'right' : 'left';

          if (image) {
            const { asset } = image;
            const { gatsbyImageData } = asset;
            imgData = gatsbyImageData;
          }
          return (
            <div
              style={{
                marginBottom: index < paragraphs.length - 1 ? '40px' : '0px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              {image && (
                <Grid
                  container
                  sx={[
                    {
                      flexDirection: {
                        md: alignment === 'left' ? 'row-reverse' : 'row',
                        xs: 'column',
                      },
                    },
                  ]}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className={classes.gridItemCenterContent}
                  >
                    <Typography
                      variant="h3"
                      className={classes.sectionTitle}
                      sx={{
                        textAlign: screenWidth <= 900 ? 'left' : textAlignment,
                      }}
                    >
                      {title}
                    </Typography>
                    <FanGoTextProcessor
                      text={text}
                      textAlign={screenWidth <= 900 ? 'left' : textAlignment}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className={classes.gridItemCenterContent}
                  >
                    <GatsbyImage
                      image={imgData}
                      alt={image?.asset?.altText || ''}
                      objectFit="cover"
                      style={{
                        borderRadius: '8px 8px 0 0',
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {!image && (
                <>
                  {renderTitleAndTextBlock({
                    title,
                    text,
                    classes,
                    titleTextAlign: 'left',
                    textBodyAlign: 'left',
                    titleVariant: 'h3',
                  })}
                </>
              )}
            </div>
          );
        })}
      </Body>

      <TrustedBrandIcons
        data={data.sanityCommonContent.trustedBy}
        paddingBottom={styles.allTen}
      />

      <BlogsPreview
        data={{
          ...data.sanityHomePage.blogsPreview,
          blogs: data.allSanityPost.nodes,
        }}
      />
    </>
  );
};

export default TicketingPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityTicketingPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityTicketingPage(language: { eq: $language }) {
      seo {
        name
        type
        title
        keywords
        description
      }
      pageHead {
        title
        _rawBody
      }
      body1 {
        title
        _rawBody
      }
      softwareFeature {
        title
        _rawBody
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 800, formats: [WEBP])
          }
          altText
        }
        productFeatures {
          cardImage {
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 400, formats: [WEBP])
            }
          }
          _key
          feature
          description
        }
      }
      paragraphs {
        title
        _rawBody
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700, formats: [WEBP])
          }
        }
      }
    }
    sanityHomePage(language: { eq: $language }) {
      blogsPreview {
        title
        buttonName
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
    }
    allSanityPost(
      sort: { publishedAt: DESC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        author
        excerpt
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 600, formats: [WEBP])
          }
          altText
        }
        category
        paragraphs {
          title
          body: _rawBody
          image {
            asset {
              altText
              url
            }
          }
        }
      }
    }
  }
`;
